



















































import Vue from 'vue';
import StoreAlert from '@/components/StoreAlert.vue';
import {SortOption, TableData, TableOptions, Task} from '@/types';
import {filterUndefKeys} from '@/helpers';
import TasksFilter from '@/components/filters/TasksFilter.vue';

function defaultOptions(): TableOptions {
  return {
    sortBy: ['id'],
    sortDesc: [true],
    page: 1,
    itemsPerPage: 20
  };
}

let timeout: any;

export default Vue.extend({
  name: 'Tasks',
  components: {TasksFilter, StoreAlert},
  data(): TableData<Task> {
    return {
      headers: [
        {value: 'id', text: 'ID'},
        {value: 'name', text: 'Name'},
        {value: 'result', text: 'Result'},
        {value: 'error', text: 'Error'},
        {value: 'attempts', text: 'Attempts left'},
        {value: 'processed_at', text: 'Processed at'},
        {value: 'created_at', text: 'Created at'},
        {value: 'updated_at', text: 'Updated at'}
      ],
      items: [],
      options: defaultOptions(),
      total: 0,
      expanded: []
    };
  },
  watch: {
    $hash() {
      this.$store.updateTableOptions(this.options, defaultOptions());
      this.load();
    }
  },
  computed: {
    hasFilter(): boolean {
      return Object.keys(this.$store.query).length > 0;
    },
    isCard(): boolean {
      return this.hasFilter && this.items.length === 1;
    },
    sort(): SortOption[] {
      const {sortBy, sortDesc} = this.options;
      return (sortBy as string[]).map((column, i) => {
        return {
          column,
          order: sortDesc[i] ? 'desc' : 'asc'
        };
      });
    }
  },
  methods: {
    updateOptions(options: TableOptions) {
      this.$store.setTableOptions(options, defaultOptions());
    },
    updateStats() {
      return this.$store.api.updateStats().then(this.load).catch(this.setError);
    },
    load() {
      this.cleanError();
      this.startLoading();
      const {page, itemsPerPage} = this.options;
      const where = this.$store.query;
      return this.$store.api.tasks({
        where: filterUndefKeys({
          hasError: where.has_error === 'true' || undefined,
          taskName: where.task_name
        }),
        sort: this.sort,
        page,
        perPage: itemsPerPage
      }).then(({items, total}) => {
        this.stopLoading();
        this.items = items;
        this.total = total;
        if (this.items.some(it => it.processed_at === null)) {
          clearTimeout(timeout);
          timeout = setTimeout(() => {
            this.load();
          }, 2000);
        }
      }).catch(this.setError);
    }
  },
  created() {
    this.$store.updateTableOptions(this.options, defaultOptions());
    this.load();
  },
  destroyed() {
    clearTimeout(timeout);
  }
});
