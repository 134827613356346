














































import Vue from 'vue';

type Data = {
  dialog: boolean,
  error: Error | null
};

export default Vue.extend({
  components: { },
  data(): Data {
    return {
      dialog: false,
      error: null
    };
  },
  computed: {
    filterHasError: {
      get(): boolean {
        return this.$store.getParam('has_error') === 'true';
      },
      set(val: boolean) {
        this.$store.setParam('has_error', val ? 'true' : '');
      }
    },
    filterTaskName: {
      get(): string | null {
        return this.$store.getParam('task_name');
      },
      set(val: string) {
        this.$store.setParam('task_name', val);
      }
    }
  },
  methods: {
    save() {
      this.dialog = false;
    }
  }
});
